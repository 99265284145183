<template>
    <!-- https://i.imgur.com/gnWevtg.png -->
    <div ref="$options" class="dropdown-options">
        <slot></slot>
        <ul class="dropdown-options__list">
            <li
                v-for="(option, index) in options"
                :key="index"
                class="dropdown-options__item"
                :class="{ 'mt-1 border-t pt-1': option.topBorder }"
            >
                <button
                    type="button"
                    class="option-button"
                    :class="{ active: index === active }"
                    @click="() => resolveHandler(option, index)"
                >
                    <div class="option-button__title">
                        <picture v-if="option.icon">
                            <img :src="option.icon" alt="icon" width="6px" height="6px" />
                        </picture>
                        <p>{{ option.title }}</p>
                    </div>
                    <transition name="slide-left">
                        <p
                            v-if="option.description && (!option.subDropdown || !!subDropdownsToggles[index])"
                            class="option-button__description"
                        >
                            {{ option.description }}
                        </p>
                    </transition>
                </button>
                <transition name="slide-down">
                    <ul
                        v-if="option.options && !!subDropdownsToggles[index]"
                        class="dropdown-options__sub-list border-t"
                    >
                        <li
                            v-for="(subOption, subIndex) in option.options"
                            :key="subIndex"
                            class="dropdown-options__sub-item"
                            :class="{ 'mt-1 border-t pt-1': subOption.topBorder }"
                        >
                            <button
                                type="button"
                                class="option-button"
                                @click="() => (subOption.handler && subOption.handler(), $emit('close'))"
                            >
                                <div class="option-button__title">
                                    <picture v-if="subOption.icon">
                                        <img :src="subOption.icon" alt="icon" width="6px" height="6px" />
                                    </picture>
                                    <p>{{ subOption.title }}</p>
                                </div>
                                <p v-if="subOption.description" class="option-button__description">
                                    {{ subOption.description }}
                                </p>
                            </button>
                        </li>
                    </ul>
                </transition>
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
export type DropdownOptionType = {
    title: string
    icon?: string
    description?: string
    topBorder?: boolean
    subDropdown?: boolean
    options?: DropdownOptionType[]
    handler?: () => void
}

const props = defineProps({
    active: Number,
    options: {
        type: Array as () => Array<DropdownOptionType>,
        required: true,
    },
})

const emits = defineEmits(['close'])

const $options = ref()

onClickOutside($options, () => emits('close'))

const subDropdownsToggles = ref([]) as Ref<boolean[]>

const resolveHandler = (option: DropdownOptionType, index: number) => {
    if (option.subDropdown) {
        subDropdownsToggles.value[index] = !subDropdownsToggles.value[index]
    } else {
        option.handler?.()
        emits('close')
    }
}
</script>

<style lang="postcss" scoped>
.dropdown-options {
    @apply z-10 overflow-hidden border border-gray-200 bg-white shadow-md;
    &__list {
        @apply p-1.5;
    }
    .option-button {
        @apply w-full space-y-1 px-2 py-1.5 text-left text-xs text-gray-800 md:rounded-lg md:opacity-60 md:grayscale md:filter lg:transition-all;
        &__title {
            @apply flex items-center space-x-2.5 md:space-x-1;
            p {
                @apply text-left text-lg font-semibold text-gray-800 md:text-xs;
            }
            picture {
                @apply block h-5 w-5;
                img {
                    @apply h-full w-full object-contain;
                }
            }
        }
        &__description {
            @apply text-sm text-gray-800 md:text-[10px] md:leading-3;
        }
        &:hover,
        &.active {
            @apply bg-site-primary bg-opacity-5 opacity-100 grayscale-0;
            .option-button__title {
                p {
                    @apply text-site-primary;
                }
            }
        }
    }
    &__sub-list {
        @apply ml-4;
    }
    &__sub-item {
        .option-button {
            &__title {
                picture {
                    @apply block h-4 w-4;
                }
            }
        }
    }
}
</style>
